import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, getDoc, getDocs, updateDoc, query, where, deleteDoc, Timestamp } from 'firebase/firestore';
import { auth, db } from './FirebaseConfig';
import Navbar from './components/Navbar';
import { Disclosure, Dialog, Transition } from '@headlessui/react';
import Swal from 'sweetalert2';
import {
    Bars3Icon,
    BellIcon,
    CogIcon,
    CreditCardIcon,
    KeyIcon,
    SquaresPlusIcon,
    UserCircleIcon,
    XMarkIcon,
    CheckIcon,
    ChevronUpIcon,
} from '@heroicons/react/24/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Apply = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userProfile, setUserProfile] = useState({ name: '', surname: '' });
    const [formData, setFormData] = useState({
        programmingSkills: [],
        computerSkills: [],
        softSkills: [],
        motivation: '',
        responsibility: '',
        status: 'incelemede',

    });
    const addSkill = (skillType, skill, event) => {
        // Preventing form submission
        event.preventDefault();

        // Correctly adding the skill
        setFormData(prevFormData => ({
            ...prevFormData,
            [skillType]: [...prevFormData[skillType], skill]
        }));
    };
    const removeSkill = (skillType, index) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [skillType]: prevFormData[skillType].filter((_, idx) => idx !== index)
        }));
    };
    const [availableDepartments, setAvailableDepartments] = useState(['Marketing', 'Campus']);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [applicationId, setApplicationId] = useState(null); // New state to store the application ID
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                fetchUserProfile(user.uid);
            } else {
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const fetchUserProfile = async (userId) => {
        try {
            const userProfileDoc = await getDoc(doc(db, 'users', userId));
            if (userProfileDoc.exists()) {
                const userProfileData = userProfileDoc.data();
                setUserProfile({
                    name: userProfileData.name,
                    surname: userProfileData.surname
                });
                const applicationsRef = collection(db, 'applications');
                const q = query(applicationsRef, where('userId', '==', userId));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const applicationData = querySnapshot.docs[0].data();
                    setFormData(applicationData);
                    setApplicationId(querySnapshot.docs[0].id);
                }
            } else {
                navigate('/profile', { state: { error: 'You need to complete your profile before applying.' } });
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
            setError('There was an error fetching your profile data.');
        }
    };


    const cancelApplication = async () => {
        if (applicationId) {
            Swal.fire({
                title: 'Başvuruyu iptal etmek istediğinize emin misiniz?',
                text: 'Bu işlem geri alınamaz!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, iptal et',
                cancelButtonText: 'Hayır, vazgeç',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await deleteDoc(doc(db, 'applications', applicationId));
                        setApplicationId(null);
                        setFormData({
                            programmingSkills: [],
                            computerSkills: [],
                            softSkills: [],
                            motivation: '',
                            responsibility: '',
                            status: "incelemede",
                        });
                        Swal.fire(
                            'Başvuru İptal Edildi!',
                            'Başvurunuz başarıyla iptal edildi.',
                            'success'
                        );
                    } catch (error) {
                        console.error('Başvuru iptali sırasında bir hata oluştu:', error);
                        Swal.fire(
                            'Başvuru İptali Başarısız!',
                            'Başvuruyu iptal etme işlemi başarısız oldu.',
                            'error'
                        );
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'İptal Edildi',
                        'Başvuru güvende :)',
                        'info'
                    );
                }
            });
        }
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
        window.location.reload();
    };


    const renderApplicationDetails = () => {
        // submittedAt değerini al
        const submittedAt = formData.submittedAt;
    
        // submittedAt varsa, Timestamp nesnesine dönüştür
        const timestamp = submittedAt ? new Timestamp(submittedAt.seconds, submittedAt.nanoseconds) : null;
    
        // Timestamp nesnesi varsa, tarihi ve saat bilgisini al
        const date = timestamp ? timestamp.toDate() : null;
    
        // Tarih bilgisini al (gün, ay, yıl)
        const formattedDate = date ? new Intl.DateTimeFormat('default', { day: 'numeric', month: 'long', year: 'numeric' }).format(date) : '';
    
        return (
            <div className="divide-y divide-gray-200 lg:col-span-9 mt-10">
                <Disclosure as="div">
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
                                <span>{formattedDate ? `Başvuru tarihi: ${formattedDate}` : 'Başvuru bilgileri'} Durumu: {formData.status} </span>
                                <ChevronUpIcon className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-white`} />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-800">
                                <div className="mb-4">
                                    <p className="font-medium text-blue-500">Programming Skills:</p>
                                    <p className="text-gray-700">{formData.programmingSkills.join(', ')}</p>
                                </div>
                                <div className="mb-4">
                                    <p className="font-medium text-blue-500">Computer Skills:</p>
                                    <p className="text-gray-700">{formData.computerSkills.join(', ')}</p>
                                </div>
                                <div className="mb-4">
                                    <p className="font-medium text-blue-500">Soft Skills:</p>
                                    <p className="text-gray-700">{formData.softSkills.join(', ')}</p>
                                </div>
                                <div className="mb-4">
                                    <p className="font-medium text-blue-500">Motivation:</p>
                                    <p className="text-gray-700">{formData.motivation}</p>
                                </div>
                                <div className="mb-4">
                                    <p className="font-medium text-blue-500">Responsibility:</p>
                                    <p className="text-gray-700">{formData.responsibility}</p>
                                </div>
                                <div className="mb-4">
                                    <p className="font-medium text-blue-500">Durum:</p>
                                    <p className="text-gray-700">{formData.status}</p>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <div className="pt-4 flex justify-end">
                    <button onClick={cancelApplication} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200">Başvurumu İptal Et</button>
                </div>
            </div>
        );
    };
    
    
    
    
    
    


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleDepartmentChange = (event) => {
        const { value, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            departments: checked
                ? [...prevFormData.departments, value]
                : prevFormData.departments.filter(dep => dep !== value)
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        try {
            // Check if we have an application ID
            if (applicationId) {
                // Update the existing application
                await updateDoc(doc(db, 'applications', applicationId), {
                    ...formData,
                    submittedAt: new Date() // Update the submission date
                });
            } else {
                // Create a new application
                await addDoc(collection(db, 'applications'), {
                    ...formData,
                    userId: auth.currentUser ? auth.currentUser.uid : null,
                    submittedAt: new Date()
                });
            }
            setIsModalOpen(true);
        } catch (firebaseError) {
            console.error('Error submitting application:', firebaseError);
            setError('Failed to submit the application.');
        } finally {
            setIsSubmitting(false);
        }
    };


    const subNavigation = [
        { name: 'Profil', href: '/profile', icon: UserCircleIcon, current: false },
        { name: 'Apply / Your Application', href: '/apply', icon: SquaresPlusIcon, current: true },
    ]



    return (

        <>
            <Navbar />
            <div>
                <Disclosure as="div" className="relative overflow-hidden bg-indigo-700 pb-32">
                    {({ open }) => (
                        <>

                            <div
                                aria-hidden="true"
                                className={classNames(
                                    open ? 'bottom-0' : 'inset-y-0',
                                    'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0'
                                )}
                            >
                                <div className="absolute inset-0 flex">
                                    <div className="h-full w-1/2" style={{ backgroundColor: '#0568A0' }} />
                                    <div className="h-full w-1/2" style={{ backgroundColor: '#0568A0' }} />
                                </div>
                                <div className="relative flex justify-center">
                                    <svg className="flex-shrink-0" width={1750} height={308} viewBox="0 0 1750 308">
                                        <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" fill="#132279" />
                                        <path d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#132279" />
                                        <path d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#0568A0" />
                                        <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" fill="#0568A0" />
                                    </svg>
                                </div>
                            </div>
                            <header className="relative py-10">
                                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                    <h1 className="text-3xl font-bold tracking-tight text-white">Başvur / Başvurum</h1>
                                </div>
                            </header>
                        </>
                    )}
                </Disclosure>
                <main className="relative -mt-32">
                    <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-x lg:divide-y-0">
                                <aside className="py-6 lg:col-span-3">
                                    <nav className="space-y-1">
                                        {subNavigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'border-teal-500 bg-teal-50 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                                                        : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                                                    'group flex items-center border-l-4 px-3 py-2 text-sm font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.current
                                                            ? 'text-teal-500 group-hover:text-teal-500'
                                                            : 'text-gray-400 group-hover:text-gray-500',
                                                        '-ml-1 mr-3 h-6 w-6 flex-shrink-0'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                <span className="truncate">{item.name}</span>
                                            </a>
                                        ))}
                                    </nav>
                                </aside>
                                        <div className="px-4 py-6 sm:p-6 lg:pb-8 divide-y divide-gray-200 lg:col-span-9">
                                            <div>
                                                <h2 className="text-lg font-medium leading-6 text-gray-900"> {userProfile.name} {userProfile.surname} olarak başvur / başvurunu görüntüle </h2>
                                                <p className="mt-1 text-sm text-gray-500">
                                                    Bu bilgiler başvurunun değerlendirilmesinde kullanılacaktır, bu nedenle ne paylaştığınıza dikkat edin.
                                                </p>
                                                <p className="text-sm font-light text-gray-500 pt-2 ">
              Bu bilgileri doldurarak <a href="/PrivacyPolicy" className="font-medium text-blue-600 hover:underline ">Gizlilik Politikası ve KVKK Aydınlatma Metni</a>'ni kabul etmiş olursunuz.
              </p>
                                            </div>
                                            {applicationId ? renderApplicationDetails() : (

                                            <form onSubmit={handleSubmit} className="">

                                            <div className="mt-6 flex flex-col lg:flex-row">
                                                <div className="flex-grow space-y-6">
                                                    <div>
                                                        <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="softSkills">
                                                            Sosyal Beceriler
                                                        </label>
                                                        <div className="flex items-center">
                                                            <input
                                                                type="text"
                                                                id="softSkillInput"
                                                                placeholder='Liderlik, takım çalışması vb.'
                                                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                            />
                                                            <button
                                                                onClick={(event) => addSkill('softSkills', document.getElementById('softSkillInput').value, event)}
                                                                className="ml-2 px-3 py-1.5 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                                            >
                                                                Ekle
                                                            </button>
                                                        </div>
                                                        <div>
                                                            {Array.isArray(formData.softSkills) && formData.softSkills.map((skill, index) => (
                                                                <span key={index} className="inline-flex items-center gap-x-0.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 my-1 mx-1">
                                                                    {skill}
                                                                    <button type="button" onClick={() => removeSkill('softSkills', index)} className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-blue-600/20">
                                                                        <span className="sr-only">Remove</span>
                                                                        <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-blue-800/50 group-hover:stroke-blue-800/75">
                                                                            <path d="M4 4l6 6m0-6l-6 6" />
                                                                        </svg>
                                                                    </button>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-y-6 lg:grid-cols-2 lg:gap-x-4">
                                                        <div>
                                                            <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="programmingSkills">
                                                                Yazılım Yetenekleri
                                                            </label>
                                                            <div className="flex items-center">
                                                                <input
                                                                    type="text"
                                                                    id="programmingSkillInput"
                                                                    placeholder='Javascript, CSS, Python vb.'
                                                                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                                />
                                                                <button
                                                                    onClick={(event) => addSkill('programmingSkills', document.getElementById('programmingSkillInput').value, event)}
                                                                    className="ml-2 px-3 py-1.5 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                                                >
                                                                    Ekle
                                                                </button>
                                                            </div>
                                                            <div>
                                                                {Array.isArray(formData.programmingSkills) && formData.programmingSkills.map((skill, index) => (
                                                                    <span key={index} className="inline-flex items-center gap-x-0.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 my-1 mx-1">
                                                                        {skill}
                                                                        <button type="button" onClick={() => removeSkill('programmingSkills', index)} className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-blue-600/20">
                                                                            <span className="sr-only">Remove</span>
                                                                            <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-blue-800/50 group-hover:stroke-blue-800/75">
                                                                                <path d="M4 4l6 6m0-6l-6 6" />
                                                                            </svg>
                                                                        </button>
                                                                    </span>
                                                                ))}
                                                            </div>

                                                        </div>
                                                        <div className="">
                                                            <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="computerSkills">
                                                                Bilgisayar Yetenekleri
                                                            </label>
                                                            <div className="flex items-center">
                                                                <input
                                                                    type="text"
                                                                    id="computerSkillInput"
                                                                    placeholder='Kullandığınız programları yazınız (Photoshop, Excel vb.)'
                                                                    className="mt-2 block w-full rounded-md border-0 px-3 py1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                                />
                                                                <button
                                                                    onClick={(event) => addSkill('computerSkills', document.getElementById('computerSkillInput').value, event)}
                                                                    className="ml-2 px-3 py-1.5 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                                                >
                                                                    Ekle
                                                                </button>
                                                            </div>
                                                            <div>
                                                                {Array.isArray(formData.computerSkills) && formData.computerSkills.map((skill, index) => (
                                                                    <span key={index} className="inline-flex items-center gap-x-0.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 my-1 mx-1">
                                                                        {skill}
                                                                        <button type="button" onClick={() => removeSkill('computerSkills', index)} className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-blue-600/20">
                                                                            <span className="sr-only">Remove</span>
                                                                            <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-blue-800/50 group-hover:stroke-blue-800/75">
                                                                                <path d="M4 4l6 6m0-6l-6 6" />
                                                                            </svg>
                                                                        </button>
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="mt-6">
                                                <label htmlFor="motivation" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Başvuru ve Tiiböt'te görev alma motivasyonunuz
                                                </label>
                                                <textarea
                                                    id="motivation"
                                                    name="motivation"
                                                    rows="4"
                                                    required
                                                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                    value={formData.motivation}
                                                    onChange={(event) => setFormData({ ...formData, motivation: event.target.value })}
                                                />
                                            </div>

                                            <div className="mt-6">
                                                <label htmlFor="responsibility" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Tiiböt'e Katıldığınızda Ne Gibi Sorumlulukları Almak İstiyorsunuz?
                                                </label>
                                                <textarea
                                                    id="responsibility"
                                                    name="responsibility"
                                                    required
                                                    rows="4"
                                                    className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                    value={formData.responsibility}
                                                    onChange={(event) => setFormData({ ...formData, responsibility: event.target.value })}
                                                />
                                            </div>
                                            <div className="mt-4 flex justify-end gap-x-3 px-4 py-4 sm:px-6">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                            </form>
                                                                            )}


                                        </div>




                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <Transition.Root show={isModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                    <div>
                                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                Başvurunuz başarıyla güncellendi
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Başvurunuz alınmıştır. İncelendikten sonra size dönüş yapılacaktır.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Tamam
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>


        </>

    );
};

export default Apply;
