import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getAuth, applyActionCode } from "firebase/auth";

function Verification() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    const auth = getAuth();
    if (oobCode) {
      applyActionCode(auth, oobCode)
        .then(() => {
          alert('Email verified successfully!');
          navigate('/');  // Redirect to profile page upon successful verification
        })
        .catch((error) => {
          alert(`Error verifying email: ${error.message}`);
          navigate('/');  // Redirect to home page on failure
        });
    }
  }, [oobCode, navigate]);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="bg-white shadow-xl rounded-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-semibold text-center text-gray-800">Verify Your Email</h1>
        <p className="mt-4 text-gray-600 text-center">
          We have sent a verification link to your email address.
          Please check your email and click on the link to verify your account.
          If you have already clicked the link, you will be redirected shortly.
        </p>
        <div className="mt-6 flex justify-center">
          <button 
            onClick={() => navigate('/')}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-200"
          >
            Return Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default Verification;
