import React, { useState, useEffect, Fragment } from 'react';
import { auth, db, storage } from './FirebaseConfig';
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Navbar from './components/Navbar';
import { Disclosure, Menu, Switch, Transition, Dialog } from '@headlessui/react';
import {
  CheckIcon,
    Bars3Icon,
    BellIcon,
    CogIcon,
    CreditCardIcon,
    KeyIcon,
    SquaresPlusIcon,
    UserCircleIcon,
    XMarkIcon,
  } from '@heroicons/react/24/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


function Profile() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState({
    name: '',
    surname: '',
    university: '',
    department: '',
    profilePicUrl: '',
    phone: ''  // Yeni eklenen alan
  });
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        loadUserProfile(currentUser.uid);
      }
    });
    return () => unsubscribe(); // Unsubscribe on unmount
  }, []);

  const loadUserProfile = async (userId) => {
    const userRef = doc(db, "users", userId);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      setProfile(docSnap.data());
    } else {
      console.log("No such document!");
    }
  };

  const DefaultProfilePic = () => (
    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevState => ({ ...prevState, [name]: value }));
  };

   // Function to calculate the completion percentage
   const calculateCompletionPercentage = () => {
    const fields = Object.keys(profile);
    const filledFields = fields.filter(field => profile[field].trim() !== '');
    return (filledFields.length / fields.length) * 100;
  };


  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (user) {
      const userRef = doc(db, "users", user.uid);
      try {
        await setDoc(userRef, profile, { merge: true });
        setIsModalOpen(true); // Show the modal on success
      } catch (error) {
        console.error("Error updating profile:", error);
        alert('Failed to update profile.');
      }
    } else {
      alert('No user is signed in.');
    }
  };

  const subNavigation = [
    { name: 'Profil', href: '/profile', icon: UserCircleIcon, current: true },
    { name: 'Başvur / Başvurunu Görüntüle', href: '/apply', icon: SquaresPlusIcon, current: false },
  ]

  const handleProfilePicUpload = async (event) => {
    const file = event.target.files[0];
    if (file && user) {
      const fileRef = ref(storage, `profilePictures/${user.uid}`);
      await uploadBytes(fileRef, file);
      const photoURL = await getDownloadURL(fileRef);
      setProfile(prevState => ({ ...prevState, profilePicUrl: photoURL }));
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, { profilePicUrl: photoURL }, { merge: true });
    }
  };

  return (
    <>


    
      <Navbar />
<div>  
      <Disclosure as="div" className="relative overflow-hidden bg-indigo-700 pb-32">
        {({ open }) => (
          <>
        
            <div
              aria-hidden="true"
              className={classNames(
                open ? 'bottom-0' : 'inset-y-0',
                'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0'
              )}
            >
              <div className="absolute inset-0 flex">
                <div className="h-full w-1/2" style={{ backgroundColor: '#0568A0' }} />
                <div className="h-full w-1/2" style={{ backgroundColor: '#0568A0' }} />
              </div>
              <div className="relative flex justify-center">
                <svg className="flex-shrink-0" width={1750} height={308} viewBox="0 0 1750 308">
                  <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" fill="#132279" />
                  <path d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#132279" />
                  <path d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#0568A0" />
                  <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" fill="#0568A0" />
                </svg>
              </div>
            </div>
            <header className="relative py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-white">Profil</h1>
              </div>
            </header>
          </>
        )}
      </Disclosure>

      <main className="relative -mt-32">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-x lg:divide-y-0">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  {subNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'border-teal-500 bg-teal-50 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                          : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center border-l-4 px-3 py-2 text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-teal-500 group-hover:text-teal-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          '-ml-1 mr-3 h-6 w-6 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </aside>

              <form className="divide-y divide-gray-200 lg:col-span-9" onSubmit={handleUpdateProfile}>
  {/* Profile section */}
  <div className="px-4 py-6 sm:p-6 lg:pb-8">
    <div>
      <h2 className="text-lg font-medium leading-6 text-gray-900">Profil</h2>
      <p className="mt-1 text-sm text-gray-500">
        Bu bilgiler herkese açık olarak görüntülenecektir, bu nedenle ne paylaştığınıza dikkat edin.
      </p>
      <p className="text-sm font-light text-gray-500 pt-2">
        Bu bilgileri doldurarak <a href="/PrivacyPolicy" className="font-medium text-blue-600 hover:underline ">Gizlilik Politikası ve KVKK Aydınlatma Metni</a>'ni kabul etmiş olursunuz.
      </p>
    </div>

    <div className="mt-6 flex flex-col lg:flex-row">
      <div className="flex-grow space-y-6">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 lg:grid-cols-2 lg:gap-x-4">
            {/* Name Input */}
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="name">
                İsim
              </label>
              <input
                id="name"
                name="name"
                type="text"
                value={profile.name}
                onChange={handleInputChange}
                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                required
              />
            </div>

            {/* Surname Input */}
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="surname">
                Soyisim
              </label>
              <input
                id="surname"
                name="surname"
                type="text"
                value={profile.surname}
                onChange={handleInputChange}
                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                required
              />
            </div>

            {/* University Input */}
            <div className="mb-4 lg:col-span-2">
              <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="university">
                Üniversite
              </label>
              <input
                id="university"
                name="university"
                type="text"
                value={profile.university}
                onChange={handleInputChange}
                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                required
              />
            </div>

            {/* Department Input */}
            <div className="mb-4 lg:col-span-2">
              <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="department">
                Bölüm
              </label>
              <input
                id="department"
                name="department"
                type="text"
                value={profile.department}
                onChange={handleInputChange}
                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                required
              />
            </div>

            {/* Phone Input */}
            <div className="mb-4 lg:col-span-2">
              <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="phone">
                Telefon Numarası
              </label>
              <input
                id="phone"
                name="phone"
                type="text"
                value={profile.phone}
                onChange={handleInputChange}
                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:border-0 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex-grow lg:ml-6 lg:mt-0 lg:flex-shrink-0 lg:flex-grow-0">
        <p className="text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
          Profil Resmi
        </p>
        <div className="mt-2 lg:hidden">
          <div className="flex items-center">
            <div className="inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-full" aria-hidden="true">
              {profile.profilePicUrl && (
                <img src={profile.profilePicUrl} alt="Profile" className="h-full w-full rounded-full" />
              )}
            </div>
            <div className="relative ml-5">
              <input
                id="mobile-user-photo"
                name="user-photo"
                type="file"
                className="peer absolute h-full w-full rounded-md opacity-0"
                onChange={handleProfilePicUpload}
              />
              <label
                htmlFor="mobile-user-photo"
                className="pointer-events-none block rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 peer-hover:ring-gray-400 peer-focus:ring-2 peer-focus:ring-sky-500"
              >
                <span>Change</span>
                <span className="sr-only"> user photo</span>
              </label>
            </div>
          </div>
        </div>

        <div className="relative hidden overflow-hidden rounded-full lg:block">
          {profile.profilePicUrl ? (
            <img src={profile.profilePicUrl} alt="Profile" className="relative h-40 w-40 rounded-full" />
          ) : (
            <svg className="h-40 w-40 text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          )}
          <label
            htmlFor="desktop-user-photo"
            className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100"
          >
            <span>Change</span>
            <span className="sr-only"> user photo</span>
            <input
              type="file"
              id="desktop-user-photo"
              name="user-photo"
              className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
              onChange={handleProfilePicUpload}
            />
          </label>
        </div>
      </div>
    </div>
  </div>

  <div className="divide-y divide-gray-200">
    <div className="mt-4 flex justify-end gap-x-3 px-4 py-4 sm:px-6">
      <button
        type="button"
        className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        İptal Et
      </button>
      <button
        type="submit"
        className="inline-flex justify-center rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
      >
        Kaydet
      </button>
    </div>
  </div>
</form>

            </div>
          </div>
        </div>
      </main>

      </div>

<Transition.Root show={isModalOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={() => setIsModalOpen(false)}>
        <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                    as="div"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                    Your profile updated successfully
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Your changes have been saved. Thank you for keeping your profile up to date.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Go back to dashboard
                            </button>
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </div>
        </div>
    </Dialog>
</Transition.Root>




    </>
  );
}

export default Profile;
