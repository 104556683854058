import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Register from './Register';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './FirebaseConfig';
import Login from './Login';
import Profile from './Profile';
import Apply from './Apply';
import EmailVerify from './EmailVerify';
import Verification from './components/Verification';
import DoesntExist from './components/DoesntExist';
import PasswordReset from './components/PasswordReset';
import RootHandler from './components/RootHandler'; // Import the RootHandler
import Home from './Home';
import PrivacyPolicy from './components/PrivacyPolicy';

const PrivateRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const Logo = 'tiibotmain.png'

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsLoading(false);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.smartsuppchat.com/loader.js?';
    
    script.onload = () => {
      window._smartsupp = window._smartsupp || {};
      window._smartsupp.key = 'c1f8dff83c038a9b5ac81650bd46e22b42c85236';
      window.smartsupp = window.smartsupp || function() {
        (window.smartsupp._ = window.smartsupp._ || []).push(arguments);
      };
    };

    document.body.appendChild(script);

    // Clean up script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (isLoading) {
    return <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="text-center">
        <img src={Logo} alt="Logo" className="mx-auto mb-4 w-40" />
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
        </div>
        <h2 className="mt-2 text-lg font-semibold text-gray-700">Yükleniyor...</h2>
    </div>
</div>;
  }

  if (user && !user.emailVerified) {
    return <Navigate to="/verify-email" state={{ from: location }} />;
  }
 
  return user ? children : <Navigate to="/login" state={{ from: location }} />;
};




function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootHandler />} />
        <Route path="/home" element={<Home />} /> 
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} /> 
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/apply" element={<PrivateRoute><Apply /></PrivateRoute>} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/verify-email" element={<EmailVerify />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="*" element={<DoesntExist />} />
      </Routes>
    </Router>
  );
}

export default App;
