import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEmailVerification, getAuth } from 'firebase/auth';

const tiibotmain = 'tiibotmain.png'

function EmailVerify() {
    const navigate = useNavigate();
    const auth = getAuth();

    // Redirect if already verified
    useEffect(() => {
        if (auth.currentUser && auth.currentUser.emailVerified) {
            navigate('/profile');
        }
    }, [navigate]);

    // Kullanıcıya doğrulama e-postası yeniden gönderme fonksiyonu
    const resendVerificationEmail = () => {
        const user = auth.currentUser;
        if (user) {
            sendEmailVerification(user)
                .then(() => {
                    alert("Doğrulama e-postası gönderildi.");
                })
                .catch((error) => {
                    alert("Doğrulama e-postası gönderilirken bir hata oluştu: ", error.message);
                });
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    src={tiibotmain}
                    alt="Tiibot Logo"
                />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    E-posta Adresinizi Doğrulayın
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    E-posta adresinize bir doğrulama bağlantısı gönderildi.
                    Lütfen e-postanızı kontrol edin ve hesabınızı doğrulamak için bağlantıya tıklayın.
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <p className="text-sm text-gray-600">
                        Eğer e-postayı almadıysanız, lütfen spam klasörünüzü kontrol edin veya{' '}
                        <a onClick={resendVerificationEmail} className="cursor-pointer font-medium text-blue-600 hover:text-blue-500">
                            doğrulama e-postasını yeniden gönderin.
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default EmailVerify;
