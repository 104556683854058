import React from 'react'
import Navbar from './Navbar'

export default function PrivacyPolicy() {
  return (
<>
<Navbar/>
<iframe className='w-screen h-screen' src="privacy-policy.html"></iframe>

</>
    )
}
