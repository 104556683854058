import React from 'react';
import { BeakerIcon } from '@heroicons/react/24/solid'
import pgwhite from './img/pgwhite.svg'; 
import foundershubwhite from './img/foundershubwhite.svg'
import segmenthub from './img/segmenthub.png'
import Navbar from './components/Navbar';

const tiibotwhite = '/tiibotwhite.png'
const Logosoft = '/tiibotmain.png'


function Home() {
  return (
<>

<div>   
  



<div className="relative isolate overflow-hidden bg-gray-900 ">
<Navbar/>


        <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply" alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />

        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
            <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{ clipPath: 'polygon(...)' }}></div>
        </div>

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl py-32 mt-12">
                <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Kariyerine doğru bir yolculuğa hazır mısın? </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-300">Ücretsiz Katıl, Kariyerinde Yüksel! 🌟 Türkiye'nin en büyük öğrenci topluluğuna sen de katıl! Her yıl düzenlediğimiz 300'den fazla etkinlikle kendini geliştir, görünürlüğünü artır. Hemen üye ol, yüzlerce kariyer ve staj fırsatını yakala! 🚀🎈</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a href="/apply" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Şimdi Başvur</a>
                    </div>
                </div>
            </div>

            
            

    
        </div>

        

        <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
            <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style={{ clipPath: 'polygon(...)' }}></div>
        </div>

        
    </div>

    <section class="bg-white ">
  <div class="grid gap-8 py-8 px-4 mx-auto max-w-screen-xl sm:gap-16 lg:gap-20 md:grid-cols-2 lg:py-16 md:px-6">
      <div class="text-gray-500 sm:text-lg dark:text-gray-400 mb-8">
          <h2 class="mb-4 text-xl font-extrabold tracking-tight text-gray-900 md:text-4xl ">Kimler bizimleydi?</h2>
          <p class="flex-auto">Binlerce şirketten uzmanlar ve profesyoneller Tiiböt'lülerle beraber oldu. Her yıl sayısı artan etinliklerimiz ve eğitimlerimizle bu firmalarla tanışma fırsatlarını kaçırma.</p>
      </div>
      <div class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 sm:grid-cols-3 dark:text-gray-400">
<div className='flex justify-center items-cente'>
<img src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/2560px-Microsoft_logo_%282012%29.svg.png' alt="" className="h-9  hover:text-gray-900 text-gray-500 grayscale transition-all hover:grayscale-0" />
</div>
<div className='flex justify-center items-cente'>
<img src='https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png' alt="" className="h-9  hover:text-gray-900 text-gray-500 grayscale transition-all hover:grayscale-0" />
</div>
<div className='flex justify-center items-cente'>
<img src='https://www.quivermanagement.com/wp-content/uploads/2021/09/Aramco-logo.png' alt="" className="h-9  hover:text-gray-900 text-gray-500 grayscale transition-all hover:grayscale-0" />
</div>
<div className='flex justify-center items-cente'>
<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/PepsiCo_logo.svg/2560px-PepsiCo_logo.svg.png" alt="" className="h-9  hover:text-gray-900 text-gray-500 grayscale transition-all hover:grayscale-0" />
</div>
<div className='flex justify-center items-cente'>
<img src="https://upload.wikimedia.org/wikipedia/fr/thumb/e/e8/Shell_logo.svg/1154px-Shell_logo.svg.png?20070628193510" alt="" className="h-9  hover:text-gray-900 text-gray-500 grayscale transition-all hover:grayscale-0" />
</div>
<div className='flex justify-center items-cente'>
<img src="https://upload.wikimedia.org/wikipedia/tr/6/6f/Turkcell_logo.png" alt="" className="h-9  hover:text-gray-900 text-gray-500 grayscale transition-all hover:grayscale-0" />
</div>
<div className='flex justify-center items-cente'>
<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Logo_of_UNICEF.svg/1280px-Logo_of_UNICEF.svg.png" alt="" className="h-9  hover:text-gray-900 text-gray-500 grayscale transition-all hover:grayscale-0" />
</div>
<div className='flex justify-center items-cente'>
<img src="https://numarasiadresi.com/wp-content/uploads/2022/02/getir-logo.png" alt="" className="h-9  hover:text-gray-900 text-gray-500 grayscale transition-all hover:grayscale-0" />
</div>
<div className='flex justify-center items-cente'>
<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/TOBB_logo.svg/1200px-TOBB_logo.svg.png" alt="" className="h-9  hover:text-gray-900 text-gray-500 grayscale transition-all hover:grayscale-0" />
</div>

             


      </div>
  </div>
</section>
    { /* Feauters */}
    <div className="bg-blue-950  sm:py-12">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:mx-0">
      <h2 className="text-2xl font-bold tracking-tight text-gray-100 sm:text-3xl">Bir adım daha yaklaş!</h2>
    </div>
    <div className="mx-auto mt-16 max-w-2xl sm:mt-8 lg:mt-12 lg:max-w-none">
      <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div className="flex flex-col">
          <dt className="text-base font-semibold leading-7 text-gray-100">
   
            🎓 1 Kasım 2018'de kuruldu
          </dt>
          <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-400">
            <p className="flex-auto">TİİBÖT, Türkiye genelinde üniversite öğrencileri için iş birlikçi bir çalışma ortamı oluşturmayı amaçlayan benzersiz bir platformdur. Misyonumuz, farklı disiplinlerden öğrencilerin bir araya gelerek öğrenmelerini, büyümelerini ve ülkemizin geleceğine katkıda bulunmalarını sağlayacak bir ortamın oluşturulmasıdır.
</p>
          </dd>
        </div>
        <div className="flex flex-col">
          <dt className="text-base font-semibold leading-7 text-gray-100">
        
          🌐 Öğrencileri Bağlayarak, Gelecek İnşa Ediyoruz
          </dt>
          <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-400">
            <p className="flex-auto">TİİBÖT, ilk olarak Türk İktisadi ve İdari Bilimler Öğrenci Topluluğu olarak kurulmuş olup mühendislik, psikoloji, girişimcilik, yazılım geliştiriciliği, mimarlık ve tarım teknolojileri gibi sayısız alanları kapsayan çok disiplinli bir topluluğa dönüşmüştür. Geniş kapsamlı ağımız, üyelerimizin hayatlarını zenginleştirmek için akademik, sosyal ve kariyer odaklı etkinlikler ve fikirler üretmemizi sağlar.</p>
          </dd>
        </div>
        <div className="flex flex-col">
          <dt className="text-base font-semibold leading-7 text-gray-100">
     
          🚀 Hızla Büyüyoruz!
          </dt>
          <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-400">
            <p className="flex-auto">Kuruluşundan beri sürekli büyüyen topluluğumuz gururla 30.000'den fazla aktif üyeye ev sahipliği yapmış ve her geçen gün sizinle büyümeye devam etmektedir!
</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>

{ /* stats */}

<div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:max-w-none">
                <div className="text-center">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Senin geleceğin için üretiyoruz</h2>
                  <p className="mt-4 text-lg leading-8 text-gray-600">Sayılarla Tiiböt</p>
                </div>
                <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                  <div className="flex flex-col bg-gray-900 p-8">
                    <dt className="text-sm font-semibold leading-6 text-gray-300">Yıllık ortalama etkinlik sayısı</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white">350+</dd>
                  </div>
                  <div className="flex flex-col bg-gray-900 p-8">
                    <dt className="text-sm font-semibold leading-6 text-gray-300">Üniversite Öğrencisi</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white">100k+</dd>
                  </div>
                  <div className="flex flex-col bg-gray-900 p-8">
                    <dt className="text-sm font-semibold leading-6 text-gray-300">Ülke</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white">3</dd>
                  </div>
                  <div className="flex flex-col bg-gray-900 p-8">
                    <dt className="text-sm font-semibold leading-6 text-gray-300">Kampüs</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white">40+</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>


          { /* ready */}

          <div className="relative isolate overflow-hidden bg-gray-900">
            <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">🚀 TİİBÖT ile Yolculuğunuza Başlamaya Hazır mısınız?
                  </h2>
                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">Hep birlikte, hepimizin geleceğine katkıda bulunacak ve hepimize daha umutlu bir yarın sunacak bir yolculuğa hazır mısınız?





                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <a href="/register" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Şimdi Başla</a>
                </div>
              </div>
            </div>
            <svg viewBox="0 0 1024 1024" className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]" aria-hidden="true">
              <circle cx="512" cy="512" r="512" fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fill-opacity="0.7" />
              <defs>
                <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
                  <stop stop-color="#7775D6" />
                  <stop offset="1" stop-color="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
          </div>


          { /* footer */}


          <footer className="bg-gray-800">
            <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
              <div className="flex justify-center space-x-6 md:order-2">
                <a href="https://facebook.com/tiibottr" className="text-white hover:text-white">
                  <span className="sr-only">Facebook</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
                  </svg>
                </a>
                <a href="https://instagram.com/tiibottr" className="text-white hover:text-white">
                  <span className="sr-only">Instagram</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                  </svg>
                </a>
                <a href="https://twitter.com/tiibottr" className="text-white hover:text-white">
                  <span className="sr-only">Twitter</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>

                <a href="https://www.linkedin.com/company/tiibot" className="text-white hover:text-white">
  <span className="sr-only">LinkedIn</span>
  <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.788-1.75-1.759s.784-1.759 1.75-1.759 1.75.788 1.75 1.759-.784 1.759-1.75 1.759zm13.5 12.268h-3v-5.569c0-1.328-.027-3.037-1.852-3.037-1.852 0-2.136 1.445-2.136 2.942v5.664h-3v-11h2.888v1.506h.041c.403-.765 1.387-1.573 2.851-1.573 3.046 0 3.611 2.006 3.611 4.615v6.452z"></path>
  </svg>
</a>

                
              </div>
              <div className="mt-8 md:order-1 md:mt-0">
                <p className="text-center text-xs leading-5 text-white">&copy; 2024 Tiiböt™ | Tüm hakları saklıdır.</p>
              </div>
            </div>
          </footer>
      
          

    </div>


 </>
 
  );
}

export default Home;
