import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Home from '../Home';

function RootHandler() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    if (mode && oobCode) {
      switch(mode) {
        case 'verifyEmail':
          navigate(`/verification?oobCode=${oobCode}`);
          break;
        case 'resetPassword':
          navigate(`/password-reset?oobCode=${oobCode}`);
          break;
        default:
          navigate('/'); // Redirect to Home if mode is unknown
          break;
      }
    }
  }, [mode, oobCode, navigate]);

  // If no specific mode or code is found, render the Home component
  return <Home />;
}

export default RootHandler
