import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getAuth, confirmPasswordReset } from "firebase/auth";

function PasswordReset() {
  const [password, setPassword] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const oobCode = searchParams.get('oobCode');

  const handleResetPassword = async () => {
    const auth = getAuth();
    try {
      await confirmPasswordReset(auth, oobCode, password);
      alert('Your password has been reset successfully.');
      navigate('/login', { replace: true });
    } catch (error) {
      alert(`Failed to reset password: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-xl font-semibold mb-4">Reset Password</h1>
        <input
          type="password"
          className="w-full p-3 border border-gray-300 rounded-lg mb-4"
          placeholder="Enter new password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button
          className="bg-blue-500 text-white w-full p-3 rounded-lg"
          onClick={handleResetPassword}
        >
          Reset Password
        </button>
      </div>
    </div>
  );
}

export default PasswordReset;
