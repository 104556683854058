import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './FirebaseConfig';
import { 
  createUserWithEmailAndPassword, 
  signInWithPopup, 
  signInWithRedirect, // Added this import
  GoogleAuthProvider, 
  onAuthStateChanged, 
  sendEmailVerification,
} from "firebase/auth";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';



const tiibotmain = 'tiibotmain.png'




function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState(''); // Şifre onayı için yeni state
  const provider = new GoogleAuthProvider();



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
          // E-posta doğrulandıysa, kullanıcıyı /profile sayfasına yönlendir
          navigate('/profile');
        
        // E-posta henüz doğrulanmadıysa, başka bir işlem yapmayın
      }
    });
    return unsubscribe; // Unsubscribe on unmount
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      toast.success('Successfully logged in with Google!');
      navigate('/profile');
    } catch (error) {
      toast.error(`Google sign-in failed: ${error.message}`);
    }
  };

  const registerWithEmail = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      // Şifreler eşleşmiyorsa kullanıcıya bir hata mesajı göster
      toast.error('Şifreler eşleşmiyor.');
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Kullanıcı kaydı başarılı, doğrulama e-postası gönder
        sendEmailVerification(userCredential.user)
          .then(() => {
            toast.success('Kayıt başarılı! Lütfen e-postanızı kontrol edip hesabınızı doğrulayın.');
          });
      })
      .catch((error) => {
        toast.error(`Kayıt sırasında bir hata oluştu: ${error.message}`);
      });
  };





  

  return (
 <>
       <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
       <section className="bg-gray-50  min-h-screen">
       <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-20 lg:py-16 lg:grid-cols-12 min-h-screen">
       <div className="w-full place-self-center lg:col-span-6">
       <div className="p-6 mx-auto bg-white rounded-lg shadow  sm:max-w-xl sm:p-8">
       <a href="/" className="inline-flex items-center mb-4 text-xl font-semibold text-gray-900 ">
                <img className=" h-10 mr-2" src={tiibotmain} alt="logo" />
                
              </a>
              <h1 className="mb-2 text-2xl font-bold leading-tight tracking-tight text-gray-900">
                Hoşgeldin 
              </h1>
              <p className="text-sm font-light text-gray-500 ">
               Zaten hesabın var mı? <a href="/login" className="font-medium text-blue-600 hover:underline ">Giriş Yap</a>.
              </p>
              <form className="mt-4 space-y-6 sm:mt-6" onSubmit={registerWithEmail}>
              <div className="grid gap-6 sm:grid-cols-2">

          <input type="hidden" name="remember" value="true" />
            
                  <div className='col-span-2'>
              <label htmlFor="email-address" className="block mb-2 text-sm font-medium text-gray-900 ">E Posta</label>
              <input 
                id="email-address" 
                name="email" 
                type="email" 
                autoComplete="email" 
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 " placeholder="ornek@gmail.com"
                      required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Şifre</label>
              <input 
                id="password" 
                name="password" 
                type="password" 
                autoComplete="current-password" 
                placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 ">Şifreni Doğrula</label>
              <input 
                id="confirm-password" 
                name="confirm-password" 
                type="password" 
                autoComplete="current-password" 
                placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </div>
          </div>
          <p className="text-sm font-light text-gray-500 ">
               Kayıt olarak <a href="/PrivacyPolicy" className="font-medium text-blue-600 hover:underline ">Gizlilik Politikası ve KVKK Aydınlatma Metni</a>'ni kabul etmiş olursunuz.
              </p>
          

          <button onClick={handleGoogleSignIn} className="w-full text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4 flex justify-center items-center">
    <FontAwesomeIcon icon={faGoogle} className="w-6 h-6 mr-2" />
    Sign in with Google
</button>


        <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Kayıt Ol</button>          
        </form>
        



    </div>
    </div>

    <div className="mr-auto place-self-center lg:col-span-6">
            <img className="hidden mx-auto lg:flex" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/authentication/illustration.svg" alt="illustration"/>  
        </div>     

    </div>

    </section>

 </>
  );
}

export default Register;
