
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration (Replace with your own config)

const firebaseConfig = {
  apiKey: "AIzaSyBBF39yx_yem2MVH_k1VybtF1fMCMmnO98",
  authDomain: "tiibot-1669f.firebaseapp.com",
  projectId: "tiibot-1669f",
  storageBucket: "tiibot-1669f.appspot.com",
  messagingSenderId: "594559923676",
  appId: "1:594559923676:web:5cf7a2f412a220357a4b19"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
