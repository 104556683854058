import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './FirebaseConfig';
import { signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const tiibotmain = 'tiibotmain.png'
  const provider = new GoogleAuthProvider();


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Redirect already logged-in user to /profile page
        navigate('/profile', { state: { message: "You are already logged in" } });
      }
    });
    return unsubscribe;
  }, [navigate, auth]); // Added 'auth' to dependency array


  async function handleLogin(e) {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Successfully logged in!');
      navigate('/profile');
    } catch (error) {
      toast.error(`Login failed: ${error.message}`);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      toast.success('Successfully logged in with Google!');
      navigate('/profile');
    } catch (error) {
      toast.error(`Google sign-in failed: ${error.message}`);
    }
  };

  const handleForgotPassword = () => {
    Swal.fire({
      title: 'E-posta adresinizi giriniz',
      input: 'email',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      cancelButtonText: 'İptal',
      confirmButtonText: 'Şifre sıfırlama epostası gönder',
      confirmButtonColor: '#2463EB', // Buton rengini burada ayarlayın
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        return sendPasswordResetEmail(auth, email).then(response => {
          toast.success('Şifre sıfırlama e-postası gönderildi!');
        }).catch(error => {
          Swal.showValidationMessage(
            `İstek başarısız oldu: ${error.message}` // Hata mesajını daha net bir şekilde göstermek için error.message kullanılır
          )
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }
  




  return (
    <>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <section className="bg-gray-50 min-h-screen">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-20 lg:py-16 lg:grid-cols-12 min-h-screen">
          <div className="w-full place-self-center lg:col-span-6">
            <div className="p-6 mx-auto bg-white rounded-lg shadow  sm:max-w-xl sm:p-8">
              <a href="/" className="inline-flex items-center mb-4 text-xl font-semibold text-gray-900 ">
                <img className="h-10 mr-2" src={tiibotmain} alt="logo" />
      
              </a>
              <h1 className="mb-2 text-2xl font-bold leading-tight tracking-tight text-gray-900 ">
                Tekrar Hoşgeldin!
              </h1>
              <p className="text-sm font-light text-gray-500 ">
                Hesabın yok mu? Saniyeler içinde Tiiböt'lü ol: <a href="/register" className="font-medium text-blue-600 hover:underline ">Kayıt Ol</a>.
              </p>
              <form onSubmit={handleLogin} className="mt-4 space-y-6 sm:mt-6">
                <div className="grid gap-6 sm:grid-cols-2">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 " htmlFor="email">
                      Eposta
                    </label>
                    <input
                      type="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="ornek@gmail.com"
                      required
                    />
                  </div>
                  <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 " htmlFor="password">
                      Şifre
                    </label>
                    <input
                      type="password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                      required
                    />
                  </div>
                </div>

              

                
                <div className="flex items-center justify-between">
                  <div className="flex items-start">

                  </div>
                  <a href="#" onClick={handleForgotPassword} className="text-sm font-medium text-blue-600 hover:underline">🐟 Şifremi unuttum</a>
                </div>
                <button onClick={handleGoogleSignIn} className="w-full text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4 flex justify-center items-center">
    <FontAwesomeIcon icon={faGoogle} className="w-6 h-6 mr-2" />
    Sign in with Google
</button>

                <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Hesabına giriş yap</button>
              </form>
            </div>
          </div>


          <div className="mr-auto place-self-center lg:col-span-6">
            <img className="hidden mx-auto lg:flex" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/authentication/illustration.svg" alt="illustration"/>  
        </div>     
        </div>
      </section>



    </>


  );
}

export default Login;
